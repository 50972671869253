<template>
    <div>
        <!--面包屑导航区-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/admin' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>撰写博客</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row :gutter="5">
            <el-col :span="21">
                <el-input placeholder="请输入文章标题" v-model="blog.title"></el-input>
            </el-col>
            <el-col :span="3">
                <el-button style="border-radius: 20px" type="danger" @click="submit">发布文章</el-button>
            </el-col>
        </el-row>
        <el-row>
            <mavon-editor v-model="blog.content" ref="md" @imgAdd="imgAdd" @change="change" style="margin: 10px auto;min-height: 70vh"/>
        </el-row>
        <el-row align="right" type="flex" justify="end">

        </el-row>
        <el-dialog class="publish_dialog" title="发布文章" :visible.sync="publishDialogFormVisible">
            <el-form style="text-align: left" ref="publishFormRef" :model="publishForm" :rules="publishFormRules"
                     class="publish_form">
                <el-form-item label="文章分类" prop="type">
                    <el-select size="small" v-model="publishForm.type" placeholder="请选择文章分类" style="margin-right: 10px"
                               @change="setBlogType">
                        <el-option v-for="type in typeList" :label="type.name" :value="type.id" :key="type.id"></el-option>
                    </el-select>
                    <el-button size="small">+ 新建分类</el-button>
                </el-form-item>
                <el-form-item label="文章标签" prop="tags">
                    <el-card shadow="never" style="height: 120px; overflow: auto">
                        <el-checkbox-group v-model="publishForm.tags">
                            <el-checkbox v-for="tag in tagList" :label="tag.id" :key="tag.id">{{tag.name}}</el-checkbox>
                        </el-checkbox-group>
                        <el-button size="mini">+ 新建标签</el-button>
                    </el-card>
                </el-form-item>
                <el-form-item label="文章类型" prop="flag">
                    <el-select size="small" v-model="publishForm.flag">
                        <el-option
                                v-for="item in flags"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="额外选项">
                    <label class="el-radio-checkbox">
                        <span class="el-checkbox__input">
                            <span class="checkbox-inner" @click="changeCommentStatus()" :class="blog.commentabled ? 'tick' : ' '"></span>
                            <input type="checkbox" class="el-checkbox__original" value="blog.commentabled">
                        </span>
                        <span class="el-checkbox__label">评论</span>                        
                    </label>
                    <label class="el-radio-checkbox">
                        <span class="el-checkbox__input">
                            <span class="checkbox-inner" @click="changeRecommendStatus()" :class="blog.recommend ? 'tick' : ' '"></span>
                                <input type="checkbox" class="el-checkbox__original" value="blog.recommend">
                         </span>
                        <span class="el-checkbox__label">推荐</span>
                    </label>
                </el-form-item>
                <el-form-item label="文章简介" prop="description">
                    <textarea class="tctextarea" v-model="publishForm.description" placeholder="请输入文章简介..."></textarea>
                </el-form-item>
                <el-form-item label="文章首图">
                    <el-upload
                            ref="upload"
                            :action="uploadPictureUrl"
                            list-type="picture-card"
                            :limit="1"
                            :on-preview="handlePictureCardPreview"
                            :on-remove="handleRemove"
                            :on-success="handleSuccess"
                            :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog 
                            :visible.sync="dialogVisible" 
                            :append-to-body="true">
                        <img width="100%" :src="dialogImageUrl" alt="预览">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="backPage">取消</el-button>
                <el-button type="primary" @click="publishBlog">发布文章</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {
            blog: {
                id: null,
                content: "",
                flag: "",
                title: "",
                type: {},
                tagIds: '',
                firstPicture: "",
                appreciation: 0,
                user: {},
                views: 0,
                description: '',
                commentabled: false,
                recommend: false,
            },
            dialogImageUrl:'',
            fileList: [],
            uploadPictureUrl: this.$blogUrl + '/upload',
            flags: [
                {value: "原创", label: "原创"},
                {value: "转载", label: "转载"},
                {value: "翻译", label: "翻译"}
            ],
            publishDialogFormVisible: false,
            publishForm: {
                type: {},
                tags: [],
                flag: '原创',
                description: ''
            },
            typeList: [],
            tagList: [],
            publishFormRules: {
                type:[
                    {required: true, message: '请选择类型', trigger: 'blur'}
                ],
                tags:[
                    {required: true, message: '请选择至少一种标签', trigger: 'blur'}
                ],
                flag:[
                    {required: true, message: '请选择文章类型', trigger: 'blur'}
                ],
                description:[
                    {required: true, message: '请填写文章简介', trigger: 'blur'},
                    {min: 1, max: 255, message: '长度不超过255个字符', trigger: 'change'}
                ],
            },
            dialogVisible: false
        }
    },

    created(){
      if (typeof this.$route.query.blog !== 'undefined'){
          this.blog = JSON.parse(this.$route.query.blog)
      }
    },

    methods: {
        // 将图片上传到服务器，返回的地址替换到md中
        async imgAdd(pos, $file) {
            // pos为位置，$file为图片文件
            let formData = new FormData();
            formData.append("file", $file);
            // 按照格式传入对于的图片文件
            axios({
                url: 'http://175.178.65.191:8090/upload',//请求地址
                method: 'post',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then((url) => {
                // 第二步.将返回的url替换到文本原位置![...](./0) -> ![...](url)
                /**
                 * $vm 指为mavonEditor实例，可以通过如下两种方式获取
                 * 1.  通过引入对象获取: `import {mavonEditor} from ...` 等方式引入后，`$vm`为`mavonEditor`
                 * 2. 通过$refs获取: html声明ref : `<mavon-editor ref=md ></mavon-editor>，`$vm`为 `this.$refs.md`
                * 3. 由于vue运行访问的路径只能在static下，so，我就把图片保存到它这里了
                */
                console.log(url.data.data)
                this.$refs.md.$img2Url(pos, url.data.data)
            })
        },

        async change(value, render) {
            this.blog.content = value;
        },

        // 获取博客类型列表
        async getTypeList() {
            const {data: res} = await this.$blog.get('/admin/getFullTypeList')
            // console.log(res.data)
            this.typeList = res.data
        },

        // 获取博客标签列表
        async getTagList() {
            const {data: res} = await this.$blog.get('/admin/getFullTagList')
            // console.log(res.data)
            this.tagList = res.data
        },

        async submit() {
            await this.getTypeList()
            await this.getTagList()
            //修改时还有文章首图没显示过来
            if (this.blog.id!==null){//修改
                this.publishForm.type = this.blog.type.name
                this.publishForm.tags = this.blog.tags.map(tag => {
                    const foundTag = this.tagList.find(item => item.name === tag.name);
                    return foundTag ? foundTag.id : null;
                });
                this.fileList = [{ url: this.blog.firstPicture}]
                this.publishForm.description = this.blog.description
                this.publishDialogFormVisible = true
            } else {//新增
                this.publishForm.type = this.typeList[0].name
                console.log(this.publishForm.type)
                this.publishDialogFormVisible = true
            }
        },

        async handleSuccess(res) {
            this.dialogImageUrl = res.data
        },

        publishBlog() {
            this.$refs.publishFormRef.validate( async valid => {
                if (!valid) return
                if(this.dialogImageUrl != ''){
                    this.blog.firstPicture = this.dialogImageUrl
                }else{
                    this.blog.firstPicture = null
                }
                this.blog.tagIds = this.publishForm.tags.toString().replace(/\[|]/g, '');
                this.blog.flag = this.publishForm.flag
                this.blog.description = this.publishForm.description
                if(this.publishForm.type == this.typeList[0].name){
                    this.blog.type = this.typeList[0]
                }
                // console.log(this.blog.description)
                this.blog.user = JSON.parse(window.sessionStorage.getItem('user'))
                const {data: res} = await this.$blog.post('/admin/blogs', {
                    blog: this.blog
                })
                // console.log(res)
                if (res.code === 200) {
                    this.publishDialogFormVisible = false
                    return this.$message.success('发布博客成功！')
                } else {
                    this.publishDialogFormVisible = false
                    return this.$message.error('发布博客失败！')
                }
            })

        },

        // 控制是否开启评论
        changeCommentStatus() {
            this.blog.commentabled = !this.blog.commentabled;
        },

        // 控制是否开启文章推荐
        changeRecommendStatus() {
            this.blog.recommend = !this.blog.recommend;
        },

        handleRemove() {
            this.dialogImageUrl = ''
        },

        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        setBlogType(res) {
            this.blog.type = this.typeList.find(item => item.id === res)
        },

        backPage(){
            this.$refs.upload.clearFiles()
            this.blog.firstPicture = ''
            this.publishDialogFormVisible = false
            this.$refs.publishFormRef.resetFields()
        }
    }
}
</script>

<style scoped lang="less">
    .tctextarea {
        width: 50%;
        height: 200px;
        min-height: 50px;
        box-sizing: border-box;
        padding: 10px;
        // border: 1px solid #eee;
        overflow-y:auto;
        display: inline-block;
        outline: none;
        caret-color:red;
        font-size:14px;
        color:#323D4D;
        line-height: 20px;
    }
    .el-form /deep/ .el-form-item__content {
        line-height: none;
    }
    .el-card /deep/ .el-card__body {
        padding: 10px;
    }
    .el-radio-checkbox {
        color: #606266;
        font-weight: 500;
        font-size: 14px;
        user-select: none;
        margin-right: 30px;
    }
    .tick::after {
        content: " ";
        position: absolute;
        display: inline-block;
        width: 12px;
        height: 6px;
        border-width: 0 0 2px 2px;
        overflow: hidden;
        border-color: #e64848;
        border-style: solid;
        -webkit-transform: rotate(-50deg);
        transform: rotate(-50deg);
        left: 3px;
        top: 4px;
    }
    .checkbox-inner {
        display: inline-block;
        position: relative;
        border: 1px solid #DCDFE6;
        border-radius: 2px;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        background-color: #FFF;
        z-index: 1;
        transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
    }

</style>